import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import img1 from "../../../static/solutions/solutions-1.png";
import img2 from "../../../static/solutions/solutions-2.png";
import img3 from "../../../static/solutions/solutions-3.png";
import rfidSeal from "../../../static/solutions/rfid-seal.png";

const Solutions = (props) => {
  const path = props.uri;
  const json = props.data.allSolutionsJson.edges;

  const billing = json[0].node;
  const rfid = json[1].node;
  const app = json[2].node;

  return (
    <Layout bodyClass="page-solutions" path={path}>
      <SEO title="Solutions" />
      <div className="container pt-8 pt-md-10" id={billing.path}>
        <div className="row mt-2 mt-md-4">
          <div className="col-12 text-center">
            <h5 className="font-italic">{billing.subtitle}</h5>
            <h1>{billing.title}</h1>
          </div>
        </div>
        <div className="row mt-2 mt-md-4 mb-2 mb-md-4 align-items-center">
          <div className="col-12 col-md-6">
            <div className="text-card">
              <div className="card-content">
                <p>{billing.introArray[0]}</p>
                <br />
                <p>{billing.introArray[1]}</p>
                <br />
                <p>{billing.introArray[2]}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-2 mt-md-4 mb-2 mb-md-4">
            <div className="d-flex justify-content-center">
              <img
                src={img1}
                alt="billing-app"
                className="billing-mockup"
                data-sal="slide-left"
                data-sal-duration="1000"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-11">
            <div className="list-card">
              <div className="list-title">
                <h3 className="mb-0">{billing.listTitle}</h3>
              </div>
              <div className="list-content">
                <div className="list">
                  {billing.list.map((listItem) => (
                    <div
                      className="list-item"
                      key={Math.random() * 20}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-10" id={rfid.path}>
        <div className="row">
          <div className="col-12 text-center">
            <h5 className="font-italic">{rfid.subtitle}</h5>
            <h1>{rfid.title}</h1>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mt-md-4 mb-2 mb-md-4">
          <div className="col-12 col-md-6">
            <div className="text-card">
              <div className="card-content">
                <p>{rfid.introArray[0]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-4 mb-2 mb-md-4 align-items-center">
          <div className="col-12 col-md-6">
            <div className="text-card">
              <div className="card-content">
                <p>{rfid.introArray[1]}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 justify-content-center text-center">
            <img
              src={rfidSeal}
              alt="rfid-seal"
              className="w-75 mockup-image"
              data-sal="slide-left"
              data-sal-duration="1000"
            />
          </div>
        </div>
        <div className="row justify-content-end mt-2 mt-md-4 mb-2 mb-md-4">
          <div className="col-12 col-md-6">
            <div className="text-card">
              <div className="card-content">
                <p>{rfid.introArray[2]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-11">
            <div className="list-card">
              <div className="list-title">
                <h3 className="mb-0">{rfid.listTitle}</h3>
              </div>
              <div className="list-content">
                <div className="list">
                  {rfid.list.map((listItem) => (
                    <div
                      className="list-item"
                      key={Math.random() * 10}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-10" id={app.path}>
        <div className="row">
          <div className="col-12 text-center">
            <h5 className="font-italic">{app.subtitle}</h5>
            <h1>{app.title}</h1>
          </div>
        </div>
        <div className="row justify-content-end align-items-center mt-2 mt-md-4 mb-2 mb-md-4">
          <div className="col-12 col-md-8 mt-2 mt-md-4 mb-2 mb-md-4">
            <div className="d-flex justify-content-center">
              <img
                className="mockup-image w-md-75"
                src={img2}
                alt="billing-app"
                data-sal="slide-right"
                data-sal-duration="1000"
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="text-card">
              <div className="card-content">
                <p>{app.intro}</p>
              </div>
            </div>
            <div className="text-card mt-2 mb-2">
              <div className="card-content">
                <p>{app.outro[2]}</p>
              </div>
            </div>
            <div className="text-card mt-2 mb-2">
              <div className="card-content">
                <p>{app.outro[3]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0 p-md-x-1">
            <div className="main-card">
              <div className="card-skew" />
              <div className="card-content w-100">
                <p>{app.outro[1]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-4 mb-2 mb-md-4">
          <div className="col-12">
            <div className="list-card">
              <div className="list-title">
                <h3 className="mb-0">{app.list1Title}</h3>
              </div>
              <div className="list-content multiline">
                <div className="list">
                  {app.list1.map((listItem, i) => {
                    if (i < 9) {
                      return (
                        <div
                          className="list-item compact"
                          key={Math.random() * 30}
                          data-sal="zoom-in"
                          data-sal-duration="800"
                        >
                          <h3 className="mb-0">{listItem}</h3>
                        </div>
                      );
                    }
                    return false;
                  })}
                </div>
                <div className="list">
                  {app.list1.map((listItem, i) => {
                    if (i > 9) {
                      return (
                        <div
                          className="list-item compact"
                          key={Math.random() * 30}
                          data-sal="zoom-in"
                          data-sal-duration="800"
                        >
                          <h3 className="mb-0">{listItem}</h3>
                        </div>
                      );
                    }
                    return false;
                  })}
                </div>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {app.list1.map((listItem, i) => {
                    if (i === 9) {
                      return (
                        <div
                          className="list-item compact"
                          key={Math.random() * 30}
                          data-sal="zoom-in"
                          data-sal-duration="800"
                        >
                          <h3 className="mb-0">{listItem}</h3>
                        </div>
                      );
                    }
                    return false;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-4 mb-2 mb-md-4 align-items-center">
          <div className="col-12 col-md-6">
            <div className="list-card">
              <div className="list-title">
                <h3 className="mb-0">{app.list2Title}</h3>
              </div>
              <div className="list-content">
                <div className="list">
                  {app.list2.map((listItem) => (
                    <div
                      className="list-item"
                      key={Math.random() * 40}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-2 mt-md-4 mb-2 mb-md-4">
            <div className="d-flex justify-content-center">
              <img
                src={img3}
                alt={app.title}
                className="billing-mockup"
                data-sal="slide-left"
                data-sal-duration="1000"
              />
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-8">
          <div className="col-12 p-0 p-md-x-1">
            <div className="main-card">
              <div className="card-skew" />
              <div className="card-content">
                <p>{app.outro[0]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mt-md-4 mb-2 mb-md-4">
          <div className="col-12 col-md-10">
            <div className="list-card">
              <div className="list-title">
                <h3 className="mb-0">{app.list3Title}</h3>
              </div>
              <div className="list-content">
                <div className="list">
                  {app.list3.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 50}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSolutionsJson {
      edges {
        node {
          title
          subtitle
          path
          outro
          listTitle
          list3Title
          list3
          list2Title
          list2
          list1Title
          list1
          list
          id
          intro
          introArray
        }
      }
    }
  }
`;

export default Solutions;
